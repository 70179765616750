import { environment } from "./../../../environments/environment";
import { Component,Pipe, ViewChild, OnInit, ElementRef, PipeTransform } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProductService } from "../../providers/product.services";
import { ToastController, Platform } from "@ionic/angular";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
  selector: "page-productDetails",
  templateUrl: "productDetails.html",
  styleUrls: ["./productDetails.scss"],
})
export class ProductDetailsPage implements OnInit {
  public productList: any = [];
  public isDataLoaded: Boolean = false;
  public searchWord: any;
  public reviewList: any = [];
  public baseUrl: any = environment.baseUrl + "/";
  public isHeaderShow: Boolean = false;
  public isProductAddedToWishList: boolean = false;
  public productDetails: any = {
    title: "",
    productDescription: "",
    productImage: "",
    price: "",
  };

  slideOpts: any = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 20,
    pagination: true,
    slidesPerView: 2,
  };

  public productId='';
  public rto_location='Bharat';


  constructor(
    private _ProductService: ProductService,
    private route: ActivatedRoute,
    public toastController: ToastController,
    private platform: Platform,
    private socialSharing: SocialSharing,
    private callNumber: CallNumber) {}

  public isSearchEnable: Boolean = false;
  public searchproductList: any = [];

  editLocation(){

  }

  share() {
    this.platform.ready().then(async () => {
      await this.socialSharing
        .share("https://play.google.com/store/apps/details?id=com.badhaobusiness.prototype")
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    });
  }


 

  onScroll(data) {
    // console.log(data.detail.scrollTop,"scrollTop")
    let scrollPoint = data.detail.scrollTop || 0;
    if (scrollPoint > 170) {
      this.isHeaderShow = true;
    }

    if (scrollPoint < 200) {
      this.isHeaderShow = false;
    }
  }

  callme(phoneNumber){
  //   this.callNumber.callNumber(mobile, true)
  // .then(res => console.log('Launched dialer!', res))
  // .catch(err => console.log('Error launching dialer', err));
  window.location.href = `tel:${phoneNumber}`;
  }

 

  getProductList() {
    let shopId = localStorage.getItem("shopID");
    this._ProductService.getProductList(shopId).subscribe((data: any) => {
      // this.fetchReviewList();
      this.productList = data;
      this.productList = this.productList.map((obj) => ({
        ...obj,
        QTY: 0,
        rating: 0,
      }));

      this.productList.forEach((product,index)=>{
        product.discountedPrice = product.price - product.price * product.discount / 100;

        if(product._id ==  this.productId){
          this.productList.splice(index,1)
        }


      })


      console.log(this.productList);
    });
  }



  // tslint:disable-next-line: member-ordering
  productDetialSlider: any = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 0,
    pagination: true,
    pager: true,
    slidesPerView: 1,
    slideShadows: true,
  };
  public isHalf = false;
  public isFull = false;

  numDifferentiation(value) {
    var val:any = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lakh';
    }
    return val;
  }

  selectprice(price) {
    if (price == "half") {
      this.isHalf = true;
      this.isFull = false;
    } else {
      this.isHalf = false;
      this.isFull = true;
    }
  }
  public showSearchBox: Boolean = false;
  enableSearch() {
    this.showSearchBox = true;
  }

  backToNormal() {
    this.showSearchBox = false;
  }

  formateDate(date) {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    let Finaldate = `${da}-${mo}-${ye}`;
    console.log(`Finaldate`, Finaldate);
    return Finaldate;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.getProductList();
      this.productId = params["id"];
      this._ProductService
        .getProductDetails(params["id"])
        .subscribe((results: any) => {
          this.isDataLoaded = true;

          // this.productDetails = data[0];
          this.productDetails = results["productData"];
          this.productDetails["createdAt"] = this.formateDate(
            this.productDetails.createdAt
          );
          this.productDetails["totalOrder"] = results["totalOrder"].length;
          this.productDetails['discountedPrice'] = this.productDetails.price - this.productDetails.price * this.productDetails.discount / 100
          this.productDetails.price = this.convertIntoLakh(this.productDetails.price)
          this.productDetails['transmission_type']=this.getTransmissionType();
          this.productDetails['fuel_type']=this.getFuelType();
          this.productDetails['milage']=this.getMilage();
          
          this.sortVarients();

   

          this.labelFormatter()
          // this.reviewList = results["reviewData"];

          if(document.getElementById("slider-box")){
            document.getElementById("slider-box").scrollIntoView();
          }
     
         

          this.productDetails["QTY"] = 0;
          this._ProductService
            .getAllWishList(
              localStorage.getItem("userId"),
              localStorage.getItem("shopID")
            )
            .subscribe((list: any) => {
              if (list.length == 0) {
                this.isProductAddedToWishList = false;
              } else {
                let isProductIdExistsInWishList = list[0].productDetails.filter(
                  function (e) {
                    return e.productId == params["id"];
                  }
                );
                if (isProductIdExistsInWishList.length !== 0) {
                  this.isProductAddedToWishList = true;
                }
              }
            });
        });
    });

    // this.getCartList();
    this.getwishList();
  }

  getMilage(){
    if(this.productDetails.addCustomeFeatures.varients){
      let mileages= [];
      this.productDetails.addCustomeFeatures.varients.forEach((details)=>{
        console.log("details",details.mileage)
        let _mileageVal= (!details.mileage)? 0 : details.mileage
          if(!mileages.includes(_mileageVal)){
            mileages.push(_mileageVal)
          }
      })

      console.log(mileages,"mileages")
      return Math.max.apply( Math, mileages );
    }else{
      return '--'
    }
  }


  getFuelType(){
    if(this.productDetails.addCustomeFeatures.varients){
      let types= [];
      this.productDetails.addCustomeFeatures.varients.forEach((details)=>{
          if(!types.includes(details.fuel_type)){
            types.push(details.fuel_type)
          }
      })

      return types.join(" , ")
    }else{
      return '--'
    }
  }

  getTransmissionType(){
    if(this.productDetails.addCustomeFeatures.varients){
      let types= [];
      this.productDetails.addCustomeFeatures.varients.forEach((details)=>{


        let _transmission= (details.transmission)?'Automatic' : 'Manual'
          if(!types.includes(_transmission)){
            types.push(_transmission)
          }
      })

      return types.join(" , ")
    }else{
      return '--'
    }
  }

  convertIntoLakh(value) {
    console.log(value,"value");
    let arr = value.split("-");
    console.log(arr,"arr");
    var v1:any =''
    var v2:any = ''
    if(arr.length == 2){
     v1 = Math.abs(arr[0]);
     v2 = Math.abs(arr[1]);
     v1 = (v1 / 100000).toFixed(2);
     v2 = (v2 / 100000).toFixed(2) ;
    }else{
     v1 = Math.abs(arr[0]);
     v1 = (v1 / 100000).toFixed(2);
     
    }
   
    let second = (v2)?" - "+v2:''
   return v1+ second + ' Lakh';
   }

  labelFormatter(){
    // this.productDetails.addCustomeFeatures
  }

 
  proDetSlide = {
    slidesPerView: 3,
    spaceBetween: 20,
    centeredSlides: false,
  };
  sliderNearyou = {
    slidesPerView: 1.9,
    centeredSlides: false,
  };

  AddToWishList(action) {
    let payload = {
      productDetails: {
        productId: this.productDetails._id,
      },
      UserId: localStorage.getItem("userId"),
      shopId: localStorage.getItem("shopID"),
    };
    this._ProductService
      .AddToWishCart(action, payload)
      .subscribe(async (data: any) => {
        let msg = "";
        if (data) {
          if (action == "add") {
            msg = "Added To WishList";
            this.isProductAddedToWishList = true;
          } else {
            msg = "Removed To WishList";
            this.isProductAddedToWishList = false;
          }

          // // const toast = await this.toastController.create({
          // //   message: msg,
          // //   duration: 3000,
          // //   color: "success",
          // //   position: "bottom",
          // //   animated: true,
          // // });
          // toast.present();
        }
      });
  }

  getwishList() {
    let shopId = localStorage.getItem("shopID");
    this._ProductService
      .getAllWishList(localStorage.getItem("userId"), shopId)
      .subscribe((data: any) => {
        console.log(data, "data");

        if (data.length !== 0) {
          let wishList = data[0].productDetails;
          wishList.forEach((list) => {
            if(list.productId){
            if (list.productId._id == this.productDetails._id) {
              this.isProductAddedToWishList = true;
            }
          }
          });
        }
      });
  }

  public overViewTab = false;
  public priceTab = false;
  public varientsTab = true;
  public featureTab = false;

  overViewTabClick(){
    this.overViewTab = true;
    this.priceTab = false;
    this.varientsTab = false;
    this.featureTab = false;
  }

  priceTabClick(){
    this.overViewTab = false;
    this.priceTab = true;
    this.varientsTab = false;
    this.featureTab = false;
  }

  varientsTabClick(){
    this.overViewTab = false;
    this.priceTab = false;
    this.varientsTab = true;
    this.featureTab = false;
  }

  featureTabClick(){
    this.overViewTab = false;
    this.priceTab = false;
    this.varientsTab = false;
    this.featureTab = true;
  }

  sortVarients(){
    console.log(this.productDetails.addCustomeFeatures.varients,"productDetails.addCustomeFeatures?.varients");

    this.productDetails.addCustomeFeatures.varients.forEach((element) => {
      if (element.order) {
        element.order = parseInt(element.order);
      } else {
        element.order = 10;
      }
    });
    let data = this.productDetails.addCustomeFeatures.varients.sort(function (
      a,
      b
    ) {
      return a.order - b.order;
    });

    this.productDetails.addCustomeFeatures.varients = JSON.parse(
      JSON.stringify(data)
    );
  }

  int(val){
    if(val){
      return parseInt(val)
    }

    return 0;
  }

  formatMoney = (amount: number) => {
    if (!amount) return;

    let val = "" + amount.toFixed(0);
    let reverse = val.split("").reverse().join("");
    let arr = reverse.split("");
    let finalArr: any[] = [];
    arr.forEach((char, index) => {
        finalArr.push(char);

        if (index == 2) {
            finalArr.push(",")
        }

        if (arr.length > 5 && index == 4) {
            finalArr.push(",")
        }
    })

    return finalArr.reverse().join("")
}


}


 