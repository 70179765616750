import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import { ProductDetailsPage} from "./pages/productDetails//productDetails";
import { NotFoundComponent } from './not-found/not-found.component';
// import { SupportComponent } from './support/support.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'csd-car/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    data: { title: 'Home | csd car App', description: 'Welcome to the homepage of our csd car App.' },
  },
  // {
  //   path: 'account',
  //   loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  // },
  
   
  {
    path: 'csd-car-blogs',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  // {
  //   path: 'compare/:id',
  //   loadChildren: () => import('./pages/carCompare/carCompare.module').then(m => m.CarComparePageModule)
  // },
  
  {
    path: 'csd-car-help-and-support',
    loadChildren: () => import('./pages/EVP/EVP.module').then(m => m.EVPPageModule)
  },
  // {
  //   path: 'show-interest/:service/:car',
  //   loadChildren: () => import('./pages/showInterest/showInterest.module').then(m => m.ShowInterestPageModule)
  // },

  {
    path: 'menu/:id',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'search/:from',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  // },
  
  {
    path: 'csd-car-more-pages',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule),
  },
  
  {
    path: 'csd-car-brands',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'csd-car-details/:id',
    // component:ProductDetailsPage
    loadChildren: () => import('./pages/productDetails/productDetails.module').then(m => m.ProductDetailsPageModule)
  },
  {
    path: 'csd-car',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },
  // { path: 'csd-car-help-and-support', component: SupportComponent }, 
  { path: '404', component: NotFoundComponent }, // Named route for 404
  { path: '**', redirectTo: '/404' } // Redirect wildcard to /404


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  declarations:[NotFoundComponent],
  exports: [RouterModule]
})
export class AppRoutingModule { }
